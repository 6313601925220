import { useDispatch, useSelector } from "react-redux";
import { setClear } from "@redux/slices/PopupForm";
import { RootState } from "@redux/reducers";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import color from "@definitions/mui/color";
import CloseIcon from "@icons/Close";
import Button from "../Button";
import Text from "../Text";
import { DialogHeader, DialogCloseButton, DialogBody } from "./styles";

type TPopupFormActionButton = {
  label: string;
  variant: "primary" | "secondary" | "text";
  loading?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: ((param?: any) => void) | undefined;
};

const FormPopup: React.FC<any> = () => {
  const {
    PopupForm,
    // loading
  } = useSelector((state: RootState) => ({
    PopupForm: state.rootReducer.PopupForm,
    loading: state.rootReducer.rootLoading,
  }));
  const dispatch = useDispatch();

  const { messages, maxWidth, image, applyButton, open, ...rest } = PopupForm;

  const _handleOnClose = () => {
    dispatch(setClear());
  };

  const hasApplyButton = applyButton?.find((elem: any) => elem.label);

  // const waitAction = applyButton
  //   ?.reduce((acc, item) => acc || loading[item.loader], false);

  const _renderImage = () => {
    const children = image;
    return (
      <Box mb={2} textAlign="center">
        {children}
      </Box>
    );
  };

  const _renderMessages = () => {
    return (
      <div>
        {messages?.map((message: string[], key: number) => {
          const child = message[0];
          const colorMUI = message[1] === "light" ? color.general.mid : color.general.main;
          const fontWeight = message[2] || "500";
          const isTitle = message[3] === "title";

          return (
            <Box key={key} mb={3}>
              <Text variant={isTitle ? "h2" : "body1"}>
                <Box
                  sx={{ color: colorMUI }}
                  fontSize={isTitle ? "24px" : "16px"}
                  fontWeight={fontWeight}
                  textAlign="center"
                >
                  {child}
                </Box>
              </Text>
            </Box>
          );
        })}
      </div>
    );
  };

  const _renderActionButton = () => {
    return (
      <>
        {applyButton?.map(
          (
            { label, variant, loading, onClick, ...rest }: Partial<TPopupFormActionButton>,
            key: number,
          ) => {
            return (
              <Box mb={2} key={key}>
                <Button
                  // disabled={waitAction}
                  loading={loading}
                  size="large"
                  width="100%"
                  variant={variant}
                  onClick={onClick}
                  {...rest}
                >
                  {label}
                </Button>
              </Box>
            );
          },
        )}
      </>
    );
  };

  return (
    open && (
      <Dialog open={true} fullWidth maxWidth={maxWidth} scroll="body" {...rest}>
        <Box css={DialogHeader}>
          <IconButton css={DialogCloseButton} onClick={_handleOnClose} size="large">
            <CloseIcon style={{ height: 16, width: 16 }} />
          </IconButton>
        </Box>
        <Box css={DialogBody}>
          {_renderImage()}
          {_renderMessages()}
          {hasApplyButton && _renderActionButton()}
        </Box>
      </Dialog>
    )
  );
};

export default FormPopup;

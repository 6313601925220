import styled from "@emotion/styled";
import custom, { fontFamPrimary } from "@definitions/mui/custom";
import { ITextProps } from "./interface";
import { colorMapper } from "@definitions/mui/color";

const MuiText: React.FC<ITextProps> = ({
  children,
  color,
  onClick,
  noWrap,
  lines,
  width,
  ...rest
}) => {

  if (noWrap) {
    return (
      <span
        css={{
          cursor: "default",
          display: "-webkit-box",
          "-webkit-line-clamp": lines,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: width,
        }}
        title={`${children}`}
      >
        {children}
      </span>
    );
  }

  return (
    <span color={color} onClick={onClick} {...rest}>
      {children}
    </span>
  );
};

const Text = styled(MuiText)<ITextProps>(
  {
    fontFamily: fontFamPrimary,
    fontSize: 16,
    fontWeight: "normal",
  },
  ({ variant = "body1" }) => ({
    ...custom.typography[variant],
  }),
  ({ clickable }) => ({
    cursor: clickable ? "pointer" : "unset",
  }),
  ({ disabled }) => ({
    userSelect: disabled ? "none" : "unset",
  }),
  ({ color }) => ({
    color: colorMapper(color ?? "general-main"),
  }),
);

export default Text;

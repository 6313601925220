import { css, SerializedStyles } from "@emotion/react";

export const DialogHeader: SerializedStyles = css({
  minHeight: "3.25rem",
  padding: "8px 16px",
  position: "relative",
});

export const DialogCloseButton: SerializedStyles = css({
  position: "absolute",
  right: 12,
  zIndex: 1
});

export const DialogBody: SerializedStyles = css({
  padding: "0 24px 24px",
});
